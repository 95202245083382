// import {
//   MenuItemsType,
//   SwapIcon,
//   SwapFillIcon,
//   EarnFillIcon,
//   EarnIcon,
//   DropdownMenuItems,
//   FarmIcon,
//   PoolIcon,
//   HomeIcon,
// } from '@zinuswap/uikit'
// import { ContextApi } from '@zinuswap/localization'

// export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
// export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
//   items?: ConfigMenuDropDownItemsType[]
// }

// const addMenuItemSupported = (item, chainId) => {
//   if (!chainId || !item.supportChainIds) {
//     return item
//   }
//   if (item.supportChainIds?.includes(chainId)) {
//     return item
//   }
//   return {
//     ...item,
//     disabled: true,
//   }
// }

// const config: (
//   t: ContextApi['t'],
//   isDark: boolean,
//   languageCode?: string,
//   chainId?: number,
// ) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
//   [
//     {
//       label: t('Dashboard'),
//       icon: HomeIcon,
//       fillIcon: HomeIcon,
//       href: '/',
//       showItemsOnMobile: false,
//     },
//     {
//       label: t('Swap'),
//       icon: SwapIcon,
//       fillIcon: SwapFillIcon,
//       href: '/swap',
//       showItemsOnMobile: false,
//     },
//     {
//       label: t('Liquidity'),
//       icon: EarnIcon,
//       fillIcon: EarnFillIcon,
//       href: '/liquidity',
//       showItemsOnMobile: false,
//     },
//     {
//       label: t('Farms'),
//       href: '/farms',
//       icon: FarmIcon,
//       fillIcon: FarmIcon,
//       showItemsOnMobile: false,
//     },
//     {
//       label: t('Staking'),
//       href: '/staking',
//       icon: PoolIcon,
//       fillIcon: PoolIcon,
//       showItemsOnMobile: false,
//     },
//     {
//       label: t('Scanner'),
//       icon: HomeIcon,
//       fillIcon: HomeIcon,
//       href: '/scanner',
//       showItemsOnMobile: false,
//     },
//   ].map((item) => addMenuItemSupported(item, chainId))

// export default config

import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  FarmIcon,
  PoolIcon,
  ChartIcon,
  AccountIcon,
  DropdownMenuItems,
  BlockIcon,
  CurrencyIcon,
  TicketIcon,
  CommunityIcon,
  TrophyGoldIcon,
  CrownIcon,
  PrizeIcon,
  RocketIcon,
  TokensIcon,
  PairsIcon,
  LiquidityNavIcon,
  FarmV2Icon,
  LpLockerIcon,
  MultisendIcon,
  BridgeIcon,
} from "@zinuswap/uikit";
import { ContextApi } from "@zinuswap/localization";
import { SUPPORT_FARMS } from "config/constants/supportChains";

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean;
};
export type ConfigMenuItemsType = Omit<MenuItemsType, "items"> & {
  hideSubNav?: boolean;
  image?: string;
} & {
  items?: ConfigMenuDropDownItemsType[];
};

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item;
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item;
  }
  return {
    ...item,
    disabled: true,
  };
};

const config: (
  t: ContextApi["t"],
  isDark: boolean,
  languageCode?: string,
  chainId?: number
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t("Chart"),
      icon: ChartIcon,
      fillIcon: SwapFillIcon,
      href: "#",
      showItemsOnMobile: false,
      items: [
        {
          label: t("Overview"),
          href: "/info",
          icon: SwapIcon,
        },

        {
          label: t("Tokens"),
          href: "info/tokens",
          icon: TokensIcon,
        },
        {
          label: t("Pairs"),
          href: "info/pairs",
          icon: PairsIcon,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t("Tools"),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: "/swap",
      showItemsOnMobile: false,
      items: [
        {
          label: t("Swap"),
          href: "/swap",
          showItemsOnMobile: false,
          icon: SwapIcon,
        },
        {
          label: t("Liquidity"),
          href: "/liquidity",
          icon: LiquidityNavIcon,
        },
        {
          label: "Multisend",
          href: "https://multisender.mainnetz.io/",
          icon: MultisendIcon,
          isExternal:true
        },
        {
          label: "LP Locker",
          href: "https://lplocker.mainnetz.io/",
          icon: LpLockerIcon,
          isExternal:true
        },

        {
          label: "Bridge",
          href: "https://bridge.zodiacswap.com/",
          icon: BridgeIcon,
          isExternal:true
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t("Earn"),
      href: "/farms",
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: "/images/decorations/pe2.png",
      items: [
        {
          label: t("Farms"),
          href: "/farms",
          icon: FarmV2Icon,
          showItemsOnMobile: false,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId));

export default config;

